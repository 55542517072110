import { store } from 'quasar/wrappers';
import { createStore } from 'vuex';

import appointment from './appointment';
import auth from './auth';
import user from './user';
import device from './device';
import basket from './basket';
import search from './search';
import catalog from './catalog';
import products from './products';
import cityForm from './city-form';
import drugstores from './drugstores';
import responsive from './responsive';
import categories from './categories';
import localStorage from './localStorage';
import advertisement from './advertisement';
import productList from './product-list';
import basketNew from './basket-new';
import vacancy from './vacancy';
import styles from './styles';
import partners from './partners';
import utils from './utils';

export default store(() => {
    const Store = createStore({
        modules: {
            appointment,
            auth,
            user,
            device,
            basket,
            search,
            catalog,
            vacancy,
            cityForm,
            products,
            basketNew,
            drugstores,
            responsive,
            categories,
            productList,
            localStorage,
            advertisement,
            styles,
            partners,
            utils,
        },

        // строгий режим только для develop
        strict: process.env.DEBUGGING,
    });

    return Store;
});
