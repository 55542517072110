import store from '/src/store/index.js';

const Store = store({ /* ssrContext */ });
const routes = [
    {
        path: '/:city?',
        component: () => import('layouts/MainLayout.vue'),
        beforeEnter: async (to, from, next) => {
            await Store.dispatch('cityForm/GET_LOCATIONS');
            let locations = await Store.getters['cityForm/LOCATIONS'];
            let cityValid = !to.params.city;

            let cityStr = to.path.replaceAll('/', '');
            if (!cityValid) {
                for (let region of locations.results) {
                    for (let city of region.cities) {
                        if (city.slug == cityStr || city.slug == to.params.city) {
                            cityValid = true;
                        }
                    }
                }
            }
            if (!cityValid && process.env.CLIENT) {
                next('/404', 404);
            } else {
                next();
            }
        },
        children: [
            { path: '', name: 'Home', component: () => import('src/pages/Index/Index.vue') },
            { path: 'search/:filters?', name: 'search', component: () => import('src/pages/Search/Index.vue') },
            {
                path: 'product/:productId', name: 'Product', component: () => import('src/pages/Product/Index.vue'),
                beforeEnter: async (to, from, next) => {
                    let locations = await Store.getters['cityForm/LOCATIONS'];
                    let cityValid = !to.params.city;
                    let cityStr = to.path.replaceAll('/', '');
                    let cityCurrent = null;
                    if (!cityValid) {
                        for (let region of locations.results) {
                            for (let city of region.cities) {
                                if (city.slug == cityStr || city.slug == to.params.city) {
                                    cityValid = true;
                                    cityCurrent = city;
                                }
                            }
                        }
                    }
                    try {
                        await Store.dispatch('products/GET_PRODUCT_BY_SLUG', {
                            id: to.params.productId,
                            city_id: cityCurrent?.id | null,
                        });
                        next();
                    } catch (err) {
                        if (process.env.CLIENT) {
                            next('/404', 404);
                        } else {
                            next();
                        }
                    }

                },
            },
            {
                path: 'catalog/:category_id?', name: 'catalog', component: () => import('src/pages/Catalog/Index.vue'),
                beforeEnter: async (to, from, next) => {
                    if (to.params.category_id) {
                        try {
                            const data = await Store.dispatch('categories/GET_NCATEGORY_BY_SLUG', {
                                data: {
                                    slug: to.params.category_id,
                                    freeDelivery: to.params.freeDelivery || false,
                                },
                            });
                            next();
                        } catch (e) {
                            if (process.env.CLIENT) {
                                next('/404', 404);
                            } else {
                                next();
                            }
                        }
                    } else {
                        next();
                    }
                },
            },
            { path: 'basket', name: 'Basket', component: () => import('pages/BasketNew/Index.vue') },
            { path: 'favorites', name: 'favorites', component: () => import('src/pages/Favorites/Index.vue') },
            { path: 'drugstores', name: 'drugstores', component: () => import('src/pages/Drugstores/Index.vue') },
            {
                path: 'personal-area',
                name: 'personal-area',
                component: () => import('src/pages/PersonalArea/Index.vue'),
            },
            { path: 'shipping', name: 'shipping', component: () => import('src/pages/Shipping/Index.vue') },
            { path: 'how-to-order', name: 'how-to-order', component: () => import('src/pages/HowToOrder/Index.vue') },
            { path: 'vacancies', name: 'vacancies', component: () => import('src/pages/Vacancies/Index.vue') },
            { path: 'faq', name: 'faq', component: () => import('src/pages/Faq/Index.vue') },
            { path: 'contacts', name: 'contacts', component: () => import('src/pages/Contacts/Index.vue') },
            { path: 'policy', name: 'policy', component: () => import('src/pages/Policy/Index.vue') },
            {
                path: 'marketing/:advertisment_id',
                name: 'marketing',
                component: () => import('src/pages/Marketing/Index.vue'),
            },
            { path: 'loyalty', name: 'loyalty', component: () => import('pages/Loyalty/Loyalty.vue') },
            {
                path: 'catalog-list-mobile/:category?',
                name: 'catalog-list-mobile',
                component: () => import('src/pages/CatalogListMobile.vue'),
            },
            { path: 'about', name: 'about', component: () => import('src/pages/About/Index.vue') },
            { path: 'agreement', name: 'agreement', component: () => import('src/pages/Agreement/Index.vue') },
            {
                path: 'shipping-and-payment',
                name: 'shipping-and-payment',
                component: () => import('src/pages/ShippingAndPayment/Index.vue'),
            },
            {
                path: 'loyalty-registration',
                name: 'loyalty-registration',
                component: () => import('pages/Loyalty/LoyaltyRegistration.vue'),
            },
            { path: 'o', name: 'orders-mobile', component: () => import('pages/Orders/OrdersMobile.vue') },
            { path: 'error-404', name: 'error-404', component: () => import('pages/Error404.vue') },
            { path: 'redirect-to-store', name: 'redirect', component: () => import('pages/Redirect') },
            {
                path: 'marketing-list',
                name: 'marketing-list',
                component: () => import('src/pages/MarketingList/Index.vue'),
            },
            {
                path: 'selling-remotely',
                name: 'selling-remotely',
                component: () => import('src/pages/SellingRemotely/Index.vue'),
            },
            {
                path: 'terms-of-sale',
                name: 'terms-of-sale',
                component: () => import('src/pages/TermsOfSale/Index.vue'),
            },
            {
                path: 'payment-complite',
                name: 'payment-complite',
                component: () => import('src/pages/PaymentComplite/Index.vue'),
            },
            {
                path: 'payment-failed',
                name: 'payment-failed',
                component: () => import('src/pages/PaymentFailed/Index.vue'),
            },
            {
                path: 'order-without-payment-created',
                name: 'order-without-payment-created',
                component: () => import('src/pages/OrderWithoutPaymentCreated/Index.vue'),
            },
            { path: '1', name: 'promocode', component: () => import('src/pages/Promocode/Index.vue') },
            { path: 'sitemap', name: 'sitemap', component: () => import('src/pages/Sitemap.vue') },
            {
                path: 'franchise',
                name: 'franchise',
                component: () => import('src/pages/Franchise/FranchiseMain/Index.vue'),
            },
            {
                path: 'franchise-new',
                name: 'franchise-new',
                component: () => import('src/pages/Franchise/FranchiseNew/Index.vue'),
            },
            {
                path: 'franchise-rebrand',
                name: 'franchise-rebrand',
                component: () => import('src/pages/Franchise/FranchiseRebrand/Index.vue'),
            },
            { path: 'unsubscribe', name: 'unsubscribe', component: () => import('src/pages/Unsubscribe/Index.vue') },
            {
                path: 'free-catalog/:category?',
                name: 'free-catalog',
                component: () => import('src/pages/FreeCatalog/Index.vue'),
            },
            { path: 'promocodes', name: 'promocodes', component: () => import('src/pages/Promocodes/Index.vue') },
            { path: 'tags/:slug?', name: 'tags', component: () => import('src/pages/Tag/Index.vue') },
            // { path: 'sber', name: 'sber', component: () => import('src/pages/SberThanks/Index.vue') },
            { path: 'legal', name: 'legal', component: () => import('src/pages/Legal/Index.vue') },
            { path: 'articles', name: 'articles', component: () => import('src/pages/Articles/Index.vue') },
            { path: 'article/:slug', name: 'article', component: () => import('src/pages/Article/Index.vue') },
            // { path: 'offices', name: 'offices', component: () => import('src/pages/Offices/Index.vue') },
            { path: 'recipes', name: 'recipes-mobile', component: () => import('src/pages/Recipes/RecipesMobile.vue') },
            { path: 'optika-article', name: 'optika-article', component: () => import('src/pages/Optika/Index.vue') },
            {
                path: 'orthopedics-article',
                name: 'orthopedics-article',
                component: () => import('src/pages/Orthopedics/Index.vue'),
            },
            {
                path: 'platform-for-partners',
                name: 'platform-for-partners',
                component: () => import('src/pages/EtablPlatformForPartners/Index.vue'),
            },
            // { path: 'alfa-bank', name: 'alfa-bank', component: () => import('src/pages/AlfaBank/Index.vue') },
            { path: 'settings', name: 'settings', component: () => import('src/pages/Settings/SettingsMobile.vue') },
            { path: 'projects', name: 'projects', component: () => import('src/pages/Projects/Index.vue') },
            { path: 'dmc', name: 'dmc', component: () => import('src/pages/Dmc/Index.vue') },
            // { path: 'basket-new', name: 'basket-new', component: () => import('src/pages/BasketNew/Index.vue') },
            {
                path: 'clients-article',
                name: 'clients-acticle',
                component: () => import('src/pages/Clients/Index.vue'),
            },
            {
                path: 'recipes-article',
                name: 'recipes-article',
                component: () => import('src/pages/RecipesDelivery/Index.vue'),
            },
            {
                path: 'certificates-article',
                name: 'certificates',
                component: () => import('src/pages/Certificates/Index.vue'),
            },
            { path: 'person-data', name: 'person-data', component: () => import('src/pages/PersonData/Index.vue') },
            { path: 'catalog-page', name: 'catalog-page', component: () => import('src/pages/CatalogPage/Index.vue') },
            {
                path: 'how-to-order-erp',
                name: 'how-to-order-erp',
                component: () => import('src/pages/HowToOrderErp.vue'),
            },
            {
                path: 'appointment-optics',
                name: 'appointment-optics',
                component: () => import('src/pages/AppointmentOptics/Index.vue'),
            },
            {
                path: 'appointment-orthopedics',
                name: 'appointment-orthopedics',
                component: () => import('src/pages/AppointmentOrthopedics/Index.vue'),
            },
            {
                path: 'recommendation-policy',
                name: 'recommendation-policy',
                component: () => import('src/pages/RecommendedTechnologies/Index.vue'),
            },
            { path: 'calendar', name: 'calendar', component: () => import('src/pages/Calendar.vue') },
            {
                path: 'how-to-make-appointment',
                name: 'how-to-make-appointment',
                component: () => import('src/pages/HowToMakeAppointment/Index.vue'),
            },
            {
                path: 'faq-image-directory',
                name: 'faq-image-directory',
                component: () => import('src/pages/FaqDeepMobile/ImageDirectory.vue'),
            },
            {
                path: 'faq-payment-refund',
                name: 'faq-payment-refund',
                component: () => import('src/pages/FaqDeepMobile/PaymentRefund.vue'),
            },
            {
                path: 'faq-exchange',
                name: 'faq-exchange',
                component: () => import('src/pages/FaqDeepMobile/Exchange.vue'),
            },
            {
                path: 'faq-delivery',
                name: 'faq-delivery',
                component: () => import('src/pages/FaqDeepMobile/Delivery.vue'),
            },
            {
                path: 'faq-ordering',
                name: 'faq-ordering',
                component: () => import('src/pages/FaqDeepMobile/Ordering.vue'),
            },
            {
                path: 'faq-how-to-delete',
                name: 'faq-how-to-delete',
                component: () => import('src/pages/FaqDeepMobile/HowToDelete.vue'),
            },
            { path: 'app', name: 'app', component: () => import('src/pages/App.vue') },
            { path: 'ya-oauth', name: 'ya-oauth', component: () => import('src/pages/YaOAuth/Index.vue') },
            { path: 'tin-auth', name: 'tin-auth', component: () => import('src/pages/TinAuth/Index.vue') },
            { path: 'vk-auth', name: 'vk-auth', component: () => import('src/pages/VKAuth/Index.vue') },
            { path: 'partneram', name: 'partneram', component: () => import('src/pages/Partneram/Index.vue') },
            {
                path: 'faq-partneram',
                name: 'faq-partneram',
                component: () => import('src/pages/FaqPartneram/Index.vue'),
            },
            { path: 'drugs/letter-:letter', name: 'drugs', component: () => import('src/pages/Drugs/Drugs.vue') },
            { path: 'promo-msk', name: 'promo-msk', component: () => import('src/pages/PromoMsk/Index.vue') },
            {
                path: 'how-to-alter-basket',
                name: 'how-to-alter-basket',
                component: () => import('src/pages/HowToAlterBasket/Index.vue'),
            },
            {
                path: 'test-card',
                name: 'test-card',
                component: () => import('src/pages/test/test.vue'),
            },
        ],
    },
    {
        path: '/:city?/404',
        component: () => import('layouts/ErrorLayout.vue'),
        children: [
            { path: '', name: 'NotFound', component: () => import('pages/Error/404/Error-404.vue') },
        ],
    },
    {
        path: '/:city?/:catchAll(.*)*',
        beforeEnter: async (to, from, next) => {
            if (process.env.CLIENT) {
                next(`/${to.params.city}/404`, 404);
            }
            return Promise.reject({ code: 404 });
        },
        component: () => import('pages/Error/404/Error-404.vue'),
    },
    {
        path: '/:city?/product/:catchAll(.*)*',
        beforeEnter: async (to, from, next) => {
            if (process.env.CLIENT) {
                next(`/${to.params.city}/404`, 404);
            }
            return Promise.reject({ code: 404 });
        },
        component: () => import('pages/Error/404/Error-404.vue'),
    },
    {
        path: '/:city?/catalog/:catchAll(.*)*',
        beforeEnter: async (to, from, next) => {
            if (process.env.CLIENT) {
                next(`/${to.params.city}/404`, 404);
            }
            return Promise.reject({ code: 404 });
        },
        component: () => import('pages/Error/404/Error-404.vue'),
    },
];

export default routes;
