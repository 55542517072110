import { request } from 'boot/axios';

const GET_SUGGEST_OKVED = async (context, payload) => {
    const customUrl = 'https://partners.etabl.ru/api/v1/integrations-partners-admin/offers/suggest_okveds';
    const data = {
        query: {
            okved: payload,
        },
    };
    return await request('get', '', data, customUrl);
};

const GET_SUGGEST_REGION = async (context, payload) => {
    const customUrl = 'https://partners.etabl.ru/api/v1/integrations-partners-admin/offers/suggest_regions';
    const data = {
        query: {
            address: payload,
        },
    };
    return await request('get', '', data, customUrl);
};
export { GET_SUGGEST_OKVED, GET_SUGGEST_REGION };